import { inject }                                     from 'aurelia-framework';
import { AdditionalInformationTypesRepository }       from 'modules/management/additional-information/additional-information-types/services/repository';
import { AdditionalInformationFileCustomListingCell } from 'modules/management/additional-information/additional-informations/custom-listing-cells/file';
import { AdditionalInformationFilesRepository }       from 'modules/management/additional-information/additional-informations/services/files-repository';
import { AdditionalInformation }                      from 'modules/management/models/additional-information';

@inject(AdditionalInformationTypesRepository, AdditionalInformationFilesRepository, AdditionalInformationFileCustomListingCell)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param additionalInformationTypesRepository
     * @param additionalInformationFilesRepository
     * @param additionalInformationFileCustomListingCell
     */
    constructor(additionalInformationTypesRepository, additionalInformationFilesRepository, additionalInformationFileCustomListingCell) {
        this.additionalInformationTypesRepository       = additionalInformationTypesRepository;
        this.additionalInformationFilesRepository       = additionalInformationFilesRepository;
        this.additionalInformationFileCustomListingCell = additionalInformationFileCustomListingCell;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {AdditionalInformation}
     */
    model() {
        let model = new AdditionalInformation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.number = {
            type:       'int',
            key:        'number',
            label:      'form.field.number',
            size:       4,
            attributes: {
                readonly: true,
            },
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.type',
            size:         4,
            remoteSource: () => this.additionalInformationTypesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.scope = {
            type:       'text',
            key:        'scope',
            label:      'form.field.scope',
            helper:     'helpers.management.additional-information.additional-informations.scope',
            size:       4,
            attributes: {
                disabled: readonly,
            },
        };

        this.description = {
            type:       'text',
            key:        'description',
            label:      'form.field.description',
            helper:     'helpers.management.additional-information.additional-informations.description',
            size:       8,
            attributes: {
                disabled: readonly,
            },
        };

        this.standard = {
            type:       'text',
            key:        'standard',
            label:      'form.field.standard',
            size:       4,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        this.referencing_data = {
            type:       'text',
            key:        'referencing_data',
            label:      'form.field.referencing-data',
            helper:     'helpers.management.additional-information.additional-informations.referencing-data',
            size:       8,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        this.file_id = {
            type:       'file-dropzone',
            key:        'file_id',
            label:      'form.field.file',
            helper:     'helpers.management.additional-information.additional-informations.file',
            required:   false,
            size:       12,
            url:        this.additionalInformationFilesRepository.uploadUri(),
            hidden:     readonly,
            mockFile:   viewModel.model.existing_file,
            attributes: {
                disabled: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.number, this.type_id, this.scope],
            [this.description, this.standard],
            [this.referencing_data],
            [this.file_id],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.additional-information.additional-informations.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.viewFileButton = {
            type:       'button',
            label:      'form.button.view-file',
            action:     () => this.additionalInformationFileCustomListingCell.downloadFile(viewModel.model),
            hidden:     !viewModel.model.file_id,
            attributes: {
                class: 'btn btn-warning',
            },
            icon:       {
                attributes: {
                    class: 'icon-eye',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.viewFileButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
