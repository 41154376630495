import { DialogService }                    from 'aurelia-dialog';
import { bindable, inject }                 from 'aurelia-framework';
import { I18N }                             from 'aurelia-i18n';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseListViewModel }                from 'base-list-view-model';
import { FilterFormSchema }                 from 'modules/management/additional-information/additional-informations/filter-form-schema';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { InfoDialog }                       from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                     from 'resources/services/app-container';
import { Downloader }                       from 'resources/services/downloader';

@inject(AppContainer, AdditionalInformationsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListAdditionalInformations extends BaseListViewModel {

    listingId = 'additional-informations-listing';

    @bindable headerTitle    = 'listing.management.additional-informations.additional-informations';
    @bindable newRecordRoute = 'management.additional-information.additional-informations.create';
    @bindable repository;
    @bindable datatable;
    @bindable row;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.additional-information.additional-informations.manage',
            'management.additional-information.additional-informations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.additional-information.additional-informations.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.additional-information.additional-informations.edit', { id: row.id }),
                visible: (row) => row.is_closed === false && this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.delete']),
            },
            options:            [
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => this.repository.closeSelected(this.datatable.instance.selectedRows).then((response) => {
                        this.appContainer.notifier.responseNotice(response);
                        this.datatable.instance.reload();
                    })),
                    visible: () => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.edit']),
                },
                {
                    label:   'form.button.reopen-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => this.repository.reopenSelected(this.datatable.instance.selectedRows).then((response) => {
                        this.appContainer.notifier.responseNotice(response);
                        this.datatable.instance.reload();
                    })),
                    visible: () => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.edit']),
                },
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.additional-informations'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: () => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.view']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-copy3',
                    action:  (row) => this.appContainer.router.navigateToRoute('management.additional-information.additional-informations.create', { duplicatingId: row.id }),
                    tooltip: 'form.button.duplicate',
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.create']),
                },
                {
                    icon:    'icon-cross',
                    action:  (row) => this.reopen(row),
                    tooltip: 'form.button.reopen',
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.edit']) && row.closed_at !== null && row.closed_by !== null,
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.close(row),
                    tooltip: 'form.button.validate',
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.additional-information.additional-informations.manage', 'management.additional-information.additional-informations.edit']) && row.closed_at === null && row.closed_by === null,
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            {
                column:    0,
                direction: 'desc',
            },
            columns:            [
                {
                    data:  'number',
                    name:  'additional_informations.number',
                    title: 'form.field.number',
                },
                {
                    data:  'type',
                    name:  'additional_information_type_translations.name',
                    title: 'form.field.type',
                },
                {
                    data:    'scope',
                    name:    'additional_informations.scope',
                    title:   'form.field.scope',
                    display: false,
                },
                {
                    data:  'description',
                    name:  'additional_information_translations.description',
                    title: 'form.field.description',
                },
                {
                    data:    'standard',
                    name:    'additional_informations.standard',
                    title:   'form.field.standard',
                    display: false,
                },
                {
                    data:    'referencing_data',
                    name:    'additional_informations.referencing_data',
                    title:   'form.field.referencing-data',
                    display: false,
                },
                {
                    data:  'created_by_name',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'additional_informations.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:       'file',
                    name:       'file',
                    title:      'form.field.file',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/additional-information/additional-informations/custom-listing-cells/file/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'is_closed',
                    name:       'is_closed',
                    title:      'form.field.validated',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/additional-information/additional-informations/custom-listing-cells/is-closed/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Closes a given record
     *
     * @param row
     */
    close(row) {
        this.repository
            .close(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Reopens a given record
     *
     * @param row
     */
    reopen(row) {
        this.repository
            .reopen(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Export zip
     *
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel);

        filterModel.selectedRows = this.datatable.instance.selectedRows;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice('O ficheiro está a ser gerado e receberá uma mensagem quando estiver concluído.')
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }

}
