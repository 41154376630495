import { inject }                           from 'aurelia-framework';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseFormViewModel }                from 'base-form-view-model';
import { FormSchema }                       from 'modules/management/additional-information/additional-informations/form-schema';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, AdditionalInformationsRepository, FormSchema)
export class CreateAdditionalInformation extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.additional-information.additional-informations.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.additional-information.additional-informations.manage',
            'management.additional-information.additional-informations.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        if (params.duplicatingId) {
            const duplicatingModel = await this.repository.find(params.duplicatingId);

            // do not duplicate the file
            delete duplicatingModel['number'];
            delete duplicatingModel['file_id'];
            delete duplicatingModel['existing_file'];

            this.initialModel.assign(duplicatingModel);
            this.model.assign(duplicatingModel);
        }

        const { next_number } = await this.repository.nextNumber();

        this.initialModel.assign({ number: next_number });
        this.model.assign({ number: next_number });

        this.schema       = this.formSchema.schema(this, false, true);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.additional-information.additional-informations.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
